import {connect} from 'react-redux';
import '../../styles/index.css';
import {Link} from 'react-router-dom';
import React from 'react';

//images
import arjeanos from '../../assets/icons/ARJEANOS.png';

function Footer(){
    { 
        // console.log('footer')
        var date = new Date();
        var year = date.getFullYear();
    }//console.log('footer')
    return(
    <footer>
        <section className="horizontal-container" style={{alignItems: 'center'}}>
            <article className="box-footer">
                <h3 className="card__subtitle">ARJÉ Club de Ciencias  </h3>
                <p className='small'>Cursos de alto rendimiento. Proyectos de investigación científica y tecnológica. Centro de asesorías. Debates científicos. Difusión de la ciencia </p>
                
                <div className='d-flex container justify-content-around' style={{width:'60%'}}>
                <Link style={{color: 'var(--green500)', textDecoration:'none', fontWeight: 'bold', fontSize: "20px"}} to='https://www.facebook.com/ArjeClub'>
                    <i class="fa fa-facebook-square" aria-hidden="true"></i>
                </Link>
                <Link style={{color: 'var(--green500)', textDecoration:'none', fontWeight: 'bold', fontSize: "20px"}} to='https://www.instagram.com/arje.club/'>
                    < i class="fa fa-instagram" aria-hidden="true"></i> 
                </Link>
                <Link style={{color: 'var(--green500)', textDecoration:'none', fontWeight: 'bold', fontSize: "20px"}} to='https://www.linkedin.com/company/arjeclub'>
                    <i class="fa fa-linkedin-square" aria-hidden="true"></i> 
                </Link>
                <Link style={{color: 'var(--green500)', textDecoration:'none', fontWeight: 'bold', fontSize: "20px"}} to='https://www.youtube.com/@arjeclubdeciencias5179'>
                    <i class="fa fa-youtube-play" aria-hidden="true"></i>
                </Link>
                </div>

                <p className='small' style={{marginTop:'1em'}}>Para conocer nuestro aviso de privacidad da click <Link style={{color: 'var(--DarkYellow)', textDecoration:'none', fontWeight: 'bold'}} to="/aviso-privacidad">aquí</Link> </p>
                <br/>
                
            </article>
            <article className="box-footer">
                <p className='small'><b>Email : </b><a className='small' style={{color: 'var(--DarkYellow)', textDecoration:'none', fontWeight: 'bold'}} href="mailto:sergio.mirazo@arjeclub.app"><i className="fa-solid fa-envelope"></i> sergio.mirazo@arjeclub.app</a> </p>
                <br/>
                <div ><img width='150px'  src={arjeanos} alt='' /></div>
                
            </article>
        </section>
        <center>
            <div className="horizontal-container" style={{alignItems: 'center', margin: '0' }}>
                <p style={{fontSize: '10px', marginTop: '1rem', textAlign: 'center' }}>2019 - {year} arjeclub. Cursos de física, matemáticas, química, robótica y programación en Hermosillo, México. Todos los derechos reservados.</p>
            </div>
        </center>
    </footer>
    )
}

const mapStateToProps = (state)=>({

})

export default connect(mapStateToProps, {

})(Footer);