function Error404(){
    return (
        <div>
            
            <div class=" d-flex container justify-content-around my-5">
            <div className="leading-8">

            <p><b>Error404</b></p>
            <p>La dirección solicitada o archivo no existe 🙈</p>
            <br/>
            <p> <a href="/"><i class="fa fa-home" aria-hidden="true"></i> <b>Ir a Home</b></a>   </p>
            </div>
            </div>
            
            
        </div>
    )
}
export default Error404;