import {connect} from 'react-redux';
import '../../styles/index.css';
import React, { Component } from "react";
import {Link} from 'react-router-dom';
import PropTypes from "prop-types";


class Asesor extends Component {
    render(){
        const {nombre, cel, especialidad, img} = this.props;
        return(
            <div style={{Width: '100%'}} className="row mx-0 d-flex justify-content-evenly ">
                <div
                                style={{backgroundColor: 'var(--gray900)'}}
                                className=" py-3 col-12 col-md-6 col-lg-4 mb-3 rounded "
                                
                            >
                <div className="container-fluid horizontal-container justify-content-evenly"> 
                <img width={'200px'} src={img} alt={nombre} className="img-fluid rounded" />
                <center>
                <h3 className="text-center font-weight-bold" style={{color: 'var(--cyan500)'}}>{nombre}</h3>
                <p className="text-center text-white"
                                style={{  width: 'fit-content', minWidth: '200px', padding: '5px', borderRadius: '5px' }}>Asesorías de {especialidad}
                </p>
                <Link className="button-primary" to={cel}>
                    <span><i className="fa-brands fa-whatsapp"></i>  Agendar cita</span>
                </Link>
                </center>
                </div>
                </div>
            </div>
        )
    }
}

Asesor.propTypes = {
    nombre: PropTypes.string.isRequired,
    cel: PropTypes.string.isRequired,
    especialidad: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    };

export default(Asesor);
