import {connect} from 'react-redux';
import '../../styles/index.css';
import React, { Component } from "react";
import PropTypes from "prop-types";

class FormCurso extends Component {
    render() {
        const { curso, formphp } = this.props;
        return(
        <div className="container d-flex align-items-center justify-content-center" style={{padding: '1rem',  backgroundColor: 'var(--gray200)'}}>
            <div className="row align-items-center">
                <div >
                    <h2 className="subtitle" style={{margin: '1rem 0 1rem 0', color: 'var(--green500)', textShadow: '1px 1px 2px var(--black), 0 2px 25px var(--cyan900), 0 0 5px var(--gray900)' }} >Formulario de inscripción  </h2>
                    <p style={{color: 'var(--white)', textAlign: 'justify', backgroundColor: 'var(--black50)', fontSize: '12px', padding: '0 1rem 0 1rem' }}>Inscríbete a nuestro curso de {curso}. </p>
                    <form action={formphp} method="POST">
                        <div className="form-group">
                            <label for="name">Nombre</label>
                            <input type="text" className="form-control" id="name" name="name" placeholder="Nombre" required/>
                        </div>
                        <div className="form-group">
                            <label for="email">Correo electrónico</label>
                            <input type="email" className="form-control" id="email" name="email" placeholder="Correo electrónico" required/>
                        </div>
                        <div className="form-group">
                            <label for="cel">Cel</label>
                            <input type="cel" className="form-control" id="cel" name="cel" placeholder="Celular" required/>
                        </div>
        
                        <div className="form-group">
                            <label for="encontre">¿Cómo nos encontraste?</label>
                            <select class="form-control" id="encontre">
                                <option>Lo encontré en Google</option>
                                <option>Lo vi en Facebook</option>
                                <option>Un amigo me contó</option>
                                <option>Seguí el enlace</option>
                            </select>
                        </div>
                        
                        <div className="form-group">
                            <label for="message">Mensaje o comentario</label>
                            <textarea className="form-control" id="message" name="message" rows="3" placeholder="Mensaje" ></textarea>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="terminos" required />
                            <label className="form-check-label" for="terminos">
                                Estoy de acuerdo con los <a class="link" href="https://arjeclub.org/aviso-privacidad">términos</a>  y condiciones
                            </label>
                        </div>
        
                        <button id="submit" type="submit" class="button-primary ">Enviar</button>
                </form>
            </div>
            </div>
        </div>   
        );
    }
}

FormCurso.propTypes = {
    curso: PropTypes.string.isRequired,
    formphp: PropTypes.string.isRequired
  };
  
  export default(FormCurso);