import {connect} from 'react-redux';
import '../../styles/index.css';
import {Link} from 'react-router-dom';
import portada from '../../assets/images/portada-grandes-cientificos.jpeg';
import React from 'react';
import AnimatedTriangles from '../animation/AnimatedTriangles';

function Principal(){
    return(
    <div
        className="mx-auto sm:w-90 lg:w-2/3 text-gray-700 text-xl items-center my-5"
        style={{
            backgroundImage: `url(${portada})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        }}
    >
        <div className='box-text my-5'>
        <h1 className="title" >ARJÉ Club de Ciencias</h1>
        <h2 className='subtitle' style={{ fontSize: '2rem'}}>Cursos de física de alto rendimiento</h2>
        <p style={{ fontSize: '16px', color: 'whitesmoke', maxWidth: '600px' }}>
        Club de ciencias para jóvenes interesados en cursos de física, matemáticas, programación, electrónica, astronomía y cosmología. Participación en el Congreso Nacional de Física, en México. Cursos de preparación para la Universidad. Participación en competencias de física y matemáticas. Museo de ciencias y divulgación científica. Debates científicos
        </p>
        <center>
        <Link className="button-primary" to="/contacto">
            <span><i className="fa-brands fa-whatsapp"></i>  Contáctanos</span>
        </Link>
        <Link className="button-ghost" to="/cursos">
            <span>Cursos</span>
        </Link>
        </center>
        </div>
        <AnimatedTriangles/>
    </div>
    );
}

const mapStateToProps = (state)=>({

})

export default connect(mapStateToProps, {
     
})(Principal);
