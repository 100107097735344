import Layout from "../../hocs/layouts/Layout";
import Navbar from "../../components/navigation/Navbar";
import React, {useState} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Footer from "../../components/navigation/Footer";
import WidePost from "../../components/boxes/WidePost";
import FormCurso from "../../components/boxes/FormCurso";
import {Link} from 'react-router-dom';
import AnimatedTriangles from "../../components/animation/AnimatedTriangles";
import ImagePopup from "../../components/boxes/ImagePopup"


//images
import PORTADA from "../../assets/images/CURSO_PYTHON.png";
import img1 from "../../assets/images/CURSO_PYTHON2.png";
import img2 from "../../assets/images/CALL_TO_ACTION.png";
import img3 from "../../assets/images/CURSO_PYTHON3.png";

//video
import VIDEO_PORTADA from "../../assets/videos/PORTADA_PYTHON.mp4";

function Python(){
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleTriangleClick = () => {
        document.getElementById('next-section').scrollIntoView({ behavior: 'smooth' });
      };
    return(
        <Layout>
            <Navbar />
            <main className="mx-auto sm:w-90 lg:w-2/3 text-gray-700 text-xl items-center my-5">
                <div>
                
                <div>
      <div className="video-container">
        <video autoPlay muted loop poster={PORTADA}>
          <source src={VIDEO_PORTADA} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay">
          <center>
            <Link className="button-primary" to="/contacto">
              <span><i className="fa-brands fa-whatsapp"></i> Contáctanos</span>
            </Link>
            <Link
              className="button-ghost"
              target="_blank"
              to="https://drive.google.com/file/d/14eu8YKzI1AzvTpQHoPSCAg1WcJIxeLdy/view?usp=sharing"
            >
              <span>
                <i className="fa fa-download" aria-hidden="true"></i> Descargar plan de estudios
              </span>
            </Link>
            <AnimatedTriangles onClick={handleTriangleClick} />
          </center>
        </div>
      </div>
      
    </div>
                
                </div>
                <div  id="next-section" className="mx-10 leading-8 my-5">
                    <h2 className="title">Nuestro Programa</h2>
                    <section class="horizontal-container">
                    
                    <article class="box">
                        <h3>LO BÁSICO DE PYTHON</h3>
                        <p>Aprende lo básico de Python desde  la declaración de variables, sus tipos, operaciones, funciones, arreglos y tuplas, clases y objetos. Explora todas las soluciones que ofrece Python con la programación orientada a objetos. Aprende sobre los entornos de desarrollo y las distintas herramientas que nos permitirán comenzar a desarrollar programas. </p>
                    </article>
                    <article class="box">
                        <h3>PON EN PRÁCTICA</h3>
                        <p> 
                        Practica elaborando algoritmos y programas que permitan resolver problemas físicos. El curso proporciona una introducción sólida a los conceptos fundamentales de la programación en Python, desde la sintaxis básica hasta herramientas más avanzadas como funciones y bibliotecas. El curso también ofrece una variedad de proyectos que permiten a los estudiantes aplicar los principios que aprendieron en un entorno práctico.
                        </p>
                    </article>
                    <article class="box">
                        <h3>NO SE NECESITA EXPERIENCIA PREVIA</h3>
                        <p>
                        El curso está dirigido a personas sin experiencia previa en programación, así como a aquellos que buscan profundizar su conocimiento. Con una combinación de contenido teórico y práctico, los estudiantes adquirirán las habilidades necesarias para realizar sus proyectos de programación con confianza
                        </p>
                    </article>
                    </section>
                </div>
                
                <center>
                <article className="container p-5 my-5">
                    <h2 className="title">No te quedes atrás y aprende el lenguaje de programación más popular en el mundo</h2>
                    <p>
                        El curso completo consta de 3 módulos. Cada módulo tiene una duración de un mes, con 3 horas de clase a la semana. El primer módulo es nivel principiante, aprenderás lo básico para empezar a programar en Python. El segundo módulo es  nivel intermedio, aprenderás funciones avanzadas y paquetería para desarrollar aplicaciones en python para dinstitnos fines. Por último, el tercer módulo es nivel avanzado, veremos una aplicación de la programación en Python para la ciencia de datos.
                    </p>
                </article>
                <article class="box-image-container">
                    <h2 >Descarga el programa</h2>
                    <ImagePopup
                    imagePath={img1}
                    />
                    
                    <Link className="button-primary" target="_blanck" to="https://drive.google.com/file/d/14eu8YKzI1AzvTpQHoPSCAg1WcJIxeLdy/view?usp=sharing">
                    <span> <i class="fa fa-download" aria-hidden="true"></i> Descargar plan de estudios</span>
                    </Link>
                </article>
                <div className="my-5" style={{backgroundColor: 'var(--cyan500)', padding: '50px', maxWidth: '400px', borderRadius: '1rem'}}>
                    <h3 style={{color: 'var(--white)'}}>
                        Te otorgamos <span style={{color: 'var(--yellow500)'}}>constancia</span>  al terminar cada uno de nuestros programas
                    </h3>
                    <p style={{color: 'var(--yellow500)'}}><i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i></p>
                </div>
                
                </center>
                <section className="my-5">
                <WidePost
                    title="Únete al mejor club de ciencias"
                    description="¡Nuestro trabajo no lo supera nadie! Ofrecemos cursos de alto nivel para formar futuros científicos o ingenieros a través de una formación científica de calidad. Somos un club de ciencias porque la convivencia sana es parte de nuestra comunidad, fomentamos los buenos valores y principios. Apoyamos a nuestros alumnos en todo lo posible para que logren formar un curriculum sólido de éxitos en resultados de olimpiadas de física y matemáticas, presentación de cartel o ponencia en el Congreso Nacional de Física, presentación de prototipos local, estatal y nacional. "
                    imagePath={img2}
                    />
                </section>
                <section className="container">
                    <center>
                    <article className="box-image-container">
                    <ImagePopup
                    imagePath={img3}
                    />
                    </article>
                    </center>
                    
                    <h2 style={{backgroundColor: 'var(--cyan500)', color: 'var(--yellow400)'}} className="my-5 py-2">Inscripciones abiertas!</h2>
                    <div className="my-5" style={{backgroundColor: 'var(--cyan500)', padding: '50px', width: '100%' , borderRadius: '1rem'}}>
                    <h3 style={{color: 'var(--white)'}}>
                        Costo de la mensualidad de <span style={{color: 'var(--yellow500)'}}>$800MXN</span>, 3 horas de clase a la semana
                    </h3>

                </div>
                
                <div>
                    <>
                    <center>
                    <Button size="lg" style={{backgroundColor: 'var(--yellow500)', color: 'var(--black)', fontWeight: 'bold', fontSize: '16px', width: '200px' }} type="button" className="btn" variant="primary" onClick={handleShow}>
                        Inscribirse
                    </Button>
                    </center>
                    

                    <Modal show={show} onHide={handleClose} >
                    <Modal.Header style={{backgroundColor: 'var(--white)', color: 'var(--cyan500)'}} closeButton>
                        
                    </Modal.Header>

                    <Modal.Body style={{backgroundColor: 'var(--white)', color: 'var(--cyan500)', maxWidth: '640px'}}>
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf7ILAGlrrIJOeYCuvcGENGUby36iLWtZdm46MOY-xqg2jJTw/viewform?embedded=true" width="100%" height="1020" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
                    </Modal.Body>

                    <Modal.Footer style={{backgroundColor: 'var(--white)', color: 'var(--cyan500)'}}>
                    
                    </Modal.Footer>
                    </Modal>
                    </>
                </div>
                
                </section>
                
            </main>
            <Footer />
        </Layout>
    )
}

export default Python;