import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'


function ImagePopup({imagePath}	) {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="ImagePopup ">
      <Image thumbnail src={imagePath} style={{cursor: 'pointer'}}  onClick={handleShow} />

      <Modal show={show} onHide={handleClose} >
        <Modal.Header style={{backgroundColor: 'var(--gray300)', color: 'var(--cyan500)'}} closeButton>
          
        </Modal.Header>

        <Modal.Body style={{backgroundColor: 'var(--gray300)', color: 'var(--cyan500)'}}>
          <Image src={imagePath} fluid />
        </Modal.Body>

        <Modal.Footer style={{backgroundColor: 'var(--gray300)', color: 'var(--cyan500)'}}>
        
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ImagePopup;