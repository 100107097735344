import Layout from "../../hocs/layouts/Layout";
import Navbar from "../../components/navigation/Navbar";
import React from "react";
import Principal from "../../components/headers/Principal";
import Footer from "../../components/navigation/Footer";

function Success(){
    setTimeout(function(){
        window.location.href = "https://arjeclub.org/";
    }, 5000);
    var timeleft = 5;
    var downloadTimer = setInterval(function(){
    timeleft--;
    
    if(timeleft <= 0)
        clearInterval(downloadTimer);
    },1000);
    return(
        <Layout>
            <Navbar />
            <>
            <main className="my-5 mx-auto sm:w-90 lg:w-2/3 text-gray-700 text-xl items-center">
            <center>
            <div style={{padding: '3rem', backgroundColor: 'var(--black)', border: '3px solid var(--white)'}} >
                <h1 style={{color: 'var(--green500)'}}>Gracias por contactarnos 🧪🔭🧲👋😄</h1>
            <hr style={{color: 'var(--green500)'}} />
            <p style={{color: 'var(--white)'}}> En breve nos pondremos en contacto contigo.</p>
            <p > <span style={{color: 'var(--white)'}} >Regresar a </span> <a href="https://arjeclub.org"> <i class="fa fa-home" aria-hidden="true"></i> Home</a></p>
            
            </div>
        </center>
            </main>
            
            </>
            <Footer />
        </Layout>
    );
}

export default Success;