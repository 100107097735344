import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';

const container = document.getElementById('root');

const RootComponent = () => {
  const [isClient, setIsClient] = useState(false);
  
  useEffect(() => {
    setIsClient(true);
  }, []);
  
  return (
    <React.StrictMode>
      {isClient ? <App /> : null}
    </React.StrictMode>
  );
};

ReactDOM.hydrateRoot(container, <RootComponent />);