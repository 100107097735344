import {connect} from 'react-redux';
import '../../styles/index.css';
import {Link} from 'react-router-dom';
import logo_arje from '../../assets/icons/ARJE-LOGO.png';
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Navbar(){
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    return(
        <div>
            <header >
                <nav className="navbar navbar-expand-md fixed-top" style={{backgroundColor: 'var(--black)'}}>
                    <div className="container-fluid" style={{color: 'var(--cyan500)', backgroundColor: 'var(--black)'}}  >
                        <Link className="navbar-brand" to="/">
                            <img className='img-fluid border-radius' src={logo_arje} alt="ARJÉ Club de Ciencias, Cursos de física de alto rendimiento" width="70px" height="50px"/>
                        </Link>
                        <button style={{color: 'var(--cyan500)'}} className="navbar-toggler"   type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse"
                            aria-expanded="false" aria-label="Toggle navigation" onClick={toggleMenu}>
                            <span style={{color: 'var(--cyan500)'}} ><i class="fa fa-bars" aria-hidden="true"></i></span>
                        </button>
                        <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarCollapse">
                            
                            <ul className="navbar-nav ms-auto mb-2 mb-md-0 d-flex container justify-content-around" style={{maxWidth: '600px'}}>
                                <li className="nav-item dropdown">
                                    <div className='drop'>

                                    <span className="button-ghost2 drop" >
                                        Cursos <i className='fa fa-caret-down' aria-hidden='false'></i>
                                    </span>
                                    
                                    <div className="dropdown-link" aria-labelledby="navbarDropdown">
                                        <a className="button-ghost3" href="/Cursos-de-alto-rendimiento">Curso de Física de Alto Rendimiento</a>
                                        <a className="button-ghost3" href="/curso-fisica">Curso de Física Básica</a>
                                        <a className="button-ghost3" href="/curso-quimica">Curso de Química</a>
                                        <a className="button-ghost3" href="/curso-python">Curso de Python</a>
                                        <a className="button-ghost3" href="/curso-calculo">Curso de Cálculo</a>
                                        <hr/>
                                        <a className="button-ghost3" href="/cursos-gratuitos">Cursos gratuitos</a>
                                    </div>
                                    </div>
                                </li>
                                
                                <li className="nav-item">
                                    <Link className="button-ghost2" to="/asesorias">
                                        <span>Asesorías</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="button-ghost2" to="https://arjeclub.app/arjedeplanck">
                                        <span>Revista Digital</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="button-ghost2" to="/eventos">
                                        <span>Eventos</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="button-ghost2" to="/consulta">
                                        <span>Material de consulta</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="button-ghost2" to="/acerca-nosotros">
                                        <span>Acerca de nosotros</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </nav>
                </header>
        </div>
    );
}

const mapStateToProps = (state)=>({

})

export default connect(mapStateToProps, {
     
})(Navbar);