import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import store from './store';
import { Provider } from 'react-redux';
import Error404 from "./containers/errors/Error404";
import Home from "./containers/pages/Home";
import Fisica from "./containers/pages/Fisica";
import Python from "./containers/pages/Python";
import Nosotros from "./containers/pages/Nosotros";
import Asesorias from "./containers/pages/Asesorias";
import Eventos from "./containers/pages/Eventos";
import Consulta from "./containers/pages/Consulta";
import Cursos from "./containers/pages/Cursos";
import Success from "./containers/pages/Success";
import CursosGratuitos from "./containers/pages/CursosGratuitos";

function App() {
  return (
    <Provider store={store}>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/curso-python" element={<Python />} />
        <Route path="*" element={<Error404 />} />
        <Route path="/Cursos-de-alto-rendimiento" element={<Fisica />} />
        <Route path="/acerca-nosotros" element={<Nosotros />} />
        <Route path="/asesorias" element={<Asesorias />} />
        <Route path="/eventos" element={<Eventos />} />
        <Route path="/consulta" element={<Consulta />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cursos" element={<Cursos />} />
        <Route path="/cursos-gratuitos" element={<CursosGratuitos />} />
      </Routes>
    </Router>
    </Provider>
  );
}

export default App;
