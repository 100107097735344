import Layout from "../../hocs/layouts/Layout";
import Navbar from "../../components/navigation/Navbar";
import React from "react";
import Footer from "../../components/navigation/Footer";
import WidePost from "../../components/boxes/WidePost";
import Asesor from "../../components/boxes/Asesor";

//images
import PORTADA from "../../assets/images/NOSOTROS_1.png";
import img1 from "../../assets/images/ASESOR1.png";
import img2 from "../../assets/images/ASESOR2.png";
import img3 from "../../assets/images/ASESOR3.png";
import img4 from "../../assets/images/ASESOR4.png";
import img5 from "../../assets/images/ASESOR5.png";
import img6 from "../../assets/images/ASESOR6.png";

const asesores = {
    asesor1:{ nombre: "Sergio Mirazo", cel: "https://api.whatsapp.com/send?phone=526629373395", especialidad: "Física, Matemáticas", img: img1 },
    asesor2:{ nombre: "Abraham V. Kraff", cel: "https://api.whatsapp.com/send?phone=526624597201", especialidad: "Filosofía", img: img2 },
    asesor3:{ nombre: "Alonso Medina", cel: "https://api.whatsapp.com/send?phone=526622404602", especialidad: "Física, Matemáticas", img: img3},
    asesor4:{ nombre: "Alejandra Jiménez", cel: "https://api.whatsapp.com/send?phone=525515276569", especialidad: "Química, Ciencias de la Salud", img: img4},
    asesor5:{ nombre: "Jesús Alberto Velázquez", cel:"https://api.whatsapp.com/send?phone=526623515570", especialidad: "Programación, Matemáticas", img: img5},
    asesor6:{ nombre: "Miguel Gómez", cel:"https://api.whatsapp.com/send?phone=526673069172", especialidad: "Física, Programación", img: img6}
}

function Asesorias(){
    
    return (
        <Layout>
            <Navbar />
            
            <main className="mx-auto sm:w-90 lg:w-2/3 text-gray-700 text-xl items-center my-5">
                <div className="container-fluid my-5 px-0" style={{Width: '100%', height: '650px', overflow: 'scroll', backgroundColor: 'var(--black)'}}>
                <h1 className="my-5" style={{color: 'var(--green500)', textAlign: 'center'}} >Asesorías</h1>

                <WidePost
                    title="Expertos en ciencias a tu disposición"
                    description="Tu éxito académico comienza aquí. Agenda cita con nosotros si necesitas asesoría sobre Física o Matemáticas. Atendemos en todos los niveles educativos, desde Secundaria, Preparatoria hasta Ingeniería y Licenciaturas. Si tienes problemas con la parte metodológica de tu proyecto de investigación o tesis, te podemos orientar. Llena el siguiente formulario con tus datos para ponernos en contacto contigo."
                    imagePath={PORTADA}
                    />
                </div>
                <div className="container-fluid my-5 px-0" style={{Width: '100%', backgroundColor: 'var(--black)'}}>
                    <h2 className="my-5" style={{color: 'var(--green500)'}} >Pasos a seguir</h2>
                    <div className="row mx-0">
                        <div className="col-12 col-md-12">
                            <div style={{Width: '100%'}} className="row mx-0 d-flex justify-content-evenly ">
                            
                                <div className="col-2">
                                    <i className="fa fa-check-circle" style={{color: 'var(--green500)', fontSize: '2rem'}}></i>
                                    <p style={{color: 'var(--cyan100)', fontSize: '12px'}}>
                                        <span style={{ fontSize: '26px', fontWeight: 'bold'}}>1</span> <br/> Selecciona tu asesor
                                    </p>
                                </div>
                                <div className="col-2">
                                    <i className="fa fa-check-circle" style={{color: 'var(--green500)', fontSize: '2rem'}}></i>
                                    <p style={{color: 'var(--cyan100)', fontSize: '12px'}}>
                                        <span style={{color: 'var(--cyan500)', fontSize: '26px', fontWeight: 'bold'}} className="text-white">2</span> <br/> Nos contactas 
                                    </p>
                                </div>
                                <div className="col-2">
                                    <i className="fa fa-check-circle" style={{color: 'var(--green500)', fontSize: '2rem'}}></i>
                                    <p style={{color: 'var(--cyan100)', fontSize: '12px'}}>
                                        <span style={{color: 'var(--cyan500)', fontSize: '26px', fontWeight: 'bold'}} className="text-white">3</span> <br/> Agenda tu cita con el asesor
                                    </p>
                                </div>
                                <div className="col-2">
                                    <i className="fa fa-check-circle" style={{color: 'var(--green500)', fontSize: '2rem'}}></i>
                                    <p style={{color: 'var(--cyan100)', fontSize: '12px'}}>
                                        <span style={{color: 'var(--cyan500)', fontSize: '26px', fontWeight: 'bold'}} className="text-white">4</span> <br/> Llevas tu asesoría en línea o presencial
                                    </p>
                                </div>
                                <div className="col-2">
                                    <i className="fa fa-check-circle" style={{color: 'var(--green500)', fontSize: '2rem'}}></i>
                                    <p style={{color: 'var(--cyan100)', fontSize: '12px'}}>
                                        <span style={{color: 'var(--cyan500)', fontSize: '26px', fontWeight: 'bold'}} className="text-white">5</span> <br/> ¡Listo! Pagas el importe de tu asesoría de $300MXN por hora
                                    </p>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid my-5 px-0" style={{Width: '100%', height: '650px', overflow: 'scroll', backgroundColor: 'var(--black)'}}>
                    <h2 className="my-5" style={{color: 'var(--green500)'}} >Nuestros asesores</h2>
                    {Object.keys(asesores).map((key, index) => (
                        <Asesor 
                        key={index}
                        nombre={asesores[key].nombre} 
                        cel={asesores[key].cel} 
                        especialidad={asesores[key].especialidad} 
                        img={asesores[key].img} />
                    ))}
                </div>
            
            <div className="mx-auto sm:w-90 lg:w-2/3 text-xl items-center my-5 z-0">
            </div>
            
            </main>
            <Footer />
        </Layout>
    );
}

export default Asesorias;