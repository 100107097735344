import {connect} from 'react-redux';
import '../../styles/index.css';
import React, { Component } from "react";
import PropTypes from "prop-types";

class WidePost extends React.Component{
    render(){
        const {title, description, imagePath} = this.props;
        
        return(
            <div className='position-relative' style={{ maxHeight: '500px', overflow: 'hidden', padding: '1rem 0 1rem 0', minHeight: '340px' }}>
            <center>
            <div className="col-12 col-lg-6 col-md-6 px-0 position-absolute top-50 start-50 translate-middle">
                <h2 className="subtitle" style={{margin: '1rem 0 1rem 0', color: 'var(--green500)', textShadow: '1px 1px 2px var(--black), 0 2px 25px var(--cyan900), 0 0 5px var(--gray900)' }} >{title}  </h2>
                <p style={{color: 'var(--white)', textAlign: 'justify', backgroundColor: 'var(--black50)', fontSize: '12px', padding: '0 0.8rem 0 0.8rem' }}>{description}</p>
            </div>
                <img src={imagePath} className="w-100 img-fluid "/>
            </center>
            </div>
            
        );
    }
}

WidePost.propTypes = {
    imagePath: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };
  
  export default(WidePost);
