import Layout from "../../hocs/layouts/Layout";
import Navbar from "../../components/navigation/Navbar";
import React from "react";
import Footer from "../../components/navigation/Footer";
import WidePost from "../../components/boxes/WidePost";

//images
import PORTADA from "../../assets/images/NOSOTROS_2.png";

function Consulta(){
    return(
        <Layout>
            <Navbar />
            
            <main className="mx-auto sm:w-90 lg:w-2/3 text-gray-700 text-xl items-center my-5">
                <div className="container-fluid my-5 px-0" style={{Width: '100%', height: '650px', overflow: 'scroll', backgroundColor: 'var(--black)'}}>
                    <h1 className="my-5" style={{color: 'var(--green500)', textAlign: 'center'}} >Medios de consulta</h1>
                    <WidePost
                    title="Infórmate de todos nuestros eventos"
                    description="En esta sección te informarás sobre todas las actividades programadas que despertarán tu curiosidad y ampliarán tu conocimiento. Las actividades varían desde nuestros valorados cursos intensivos de física teórica hasta las sesiones de Cineclub donde exploraremos la ciencia a través de la gran pantalla. Además, se organizan debates científicos que te ofrecen la oportunidad de reflexionar y discutir sobre temas actuales y fascinantes en el mundo de la ciencia con otros apasionados del ámbito. Cada evento está cuidadosamente diseñado para englobar tanto la diversión como el aprendizaje, asegurando una experiencia enriquecedora para todos nuestros miembros. ¡Asegúrate de revisar esta sección regularmente para no perderte nada de lo que ARJÉ Club de Ciencias tiene para ofrecer!"
                    imagePath={PORTADA}
                    />
                </div>
            </main>
            <Footer />
        </Layout>
    );
}

export default Consulta;