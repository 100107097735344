import Layout from "../../hocs/layouts/Layout";
import Navbar from "../../components/navigation/Navbar";
import React from "react";
import Footer from "../../components/navigation/Footer";
import WidePost from "../../components/boxes/WidePost";
import Form from "../../components/boxes/Form";

//images
import img1 from "../../assets/images/NOSOTROS_1.png";
import img2 from "../../assets/images/NOSOTROS_2.png";
import img3 from "../../assets/images/NOSOTROS_3.png";

function Nosotros(){
    return (
        <Layout>
            <Navbar />
            
            <main className="mx-auto sm:w-90 lg:w-2/3 text-gray-700 text-xl items-center my-5">
                <div className="container-fluid my-5 px-0" style={{Width: '100%', height: '650px', overflow: 'scroll', backgroundColor: 'var(--black)'}}>
                <h1 className="my-5" style={{color: 'var(--green500)', textAlign: 'center'}} >Acerca de nosotros</h1>

                <WidePost
                    title="¿Quiénes somos?"
                    description="Somos un grupo de científicos y profesores de física y matemáticas que nos dedicamos a la enseñanza de la ciencia y la tecnología. Nuestra meta es formar futuros científicos o ingenieros a través de una formación científica de calidad. Somos un club de ciencias porque la convivencia sana es parte de nuestra comunidad, fomentamos los buenos valores y principios. Apoyamos a nuestros alumnos en todo lo posible para que logren formar un curriculum sólido de éxitos en resultados de olimpiadas de física y matemáticas, presentación de cartel o ponencia en el Congreso Nacional de Física, presentación de prototipos local, estatal y nacional."
                    imagePath={img1}
                    />
                <WidePost
                    title="¿Qué hacemos?"
                    description="Este club de ciencias se compromete en fomentar el interés y la pasión por las ciencias en jóvenes estudiantes, proporcionándoles oportunidades para ampliar sus conocimientos y destrezas en estas áreas. El equipo de ARJÉ Club de Ciencias está compuesto por profesionales y entusiastas de la ciencia que se dedican a enseñar y a inspirar en su comunidad estudiantil el amor y la comprensión de las ciencias.

                    Sus cursos se diseñan para retar y estimular la mente de los jóvenes, fomentando un enfoque práctico y atractivo para el aprendizaje. Los cursos de física teórica, matemáticas, química, ciencias médicas, programación y robótica están diseñados para proporcionar una base sólida y dar un impulso a los estudiantes en sus futuras carreras científicas.
                    "
                    imagePath={img2}
                    />
                <WidePost
                    title="¿Por qué elegirnos?"
                    description="Nuestro trabajo no lo supera nadie! Ofrecemos cursos de alto nivel para formar futuros científicos o ingenieros a través de una formación científica de calidad. Apoyamos a nuestros alumnos en todo lo posible para que logren formar un curriculum sólido de éxitos en resultados de olimpiadas de física y matemáticas, presentación de cartel o ponencia en el Congreso Nacional de Física, presentación de prototipos local, estatal y nacional. ARJÉ Club de Ciencias cree firmemente en la importancia de la investigación y la difusión científica."
                    imagePath={img3}
                    />
            </div>
            </main>
            <Form />
            <Footer />
        </Layout>
    );
}

export default Nosotros;