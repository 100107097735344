import React from 'react';
import '../../styles/index.css';
import { Component } from "react";
import PropTypes from "prop-types";

class Sway extends Component {
  render() {
    const { link, title, description } = this.props;

    return (
      <center>
        <div
        style={{backgroundColor: 'var(--black)'}}
        className="col-12 col-md-6 col-lg-4 mb-3 rounded "
        
      >
        <h3
          className="text-center display-4 bg-light font-weight-bold algunColor"
        >
          {title}
        </h3>
        <iframe width="100%" height="500px" src={link} frameborder="0" marginheight="0" marginwidth="0" max-width="100%" sandbox="allow-forms allow-modals allow-orientation-lock allow-popups allow-same-origin allow-scripts" scrolling="no" class="border-none max-w-full max-h-screen" allowfullscreen mozallowfullscreen msallowfullscreen webkitallowfullscreen></iframe>
        <p
          className="text-center text-white"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
        >
          {description}
        </p>
      </div>
      </center>
      
    );
  }
}

Sway.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default(Sway);