import Layout from "../../hocs/layouts/Layout";
import Navbar from "../../components/navigation/Navbar";
import React from "react";
import Footer from "../../components/navigation/Footer";
import { Link } from "react-router-dom";
import Curso from "../../components/boxes/Curso";
//images
import PORTADA from "../../assets/images/PORTADA_CURSOS.png";
import img1 from "../../assets/images/ASESOR1.png";
import CURSO1 from "../../assets/images/CURSO1.png";
import CURSO2 from "../../assets/images/CURSO2.png";
import CURSO3 from "../../assets/images/CURSO3.png";

//video
import VIDEO_PORTADA from "../../assets/videos/PORTADA_CURSOS_GRATUITOS.mp4";


function Cursos(){
    const cursos = {
        curso1:{ nombre: "Python", descripcion: "Aprende a programar en Python", img: CURSO1, path: "/curso-python"},
        curso2:{ nombre: "Cálculo Diferencial", descripcion: "Aprende Cálculo Diferencial", img: CURSO2, path: "#"},
        curso3:{ nombre: "Cálculo Integral", descripcion: "Aprende Cálculo Integral", img: CURSO3, path: "/Cursos-de-alto-rendimiento"}
    }
    
    return (
        <Layout>
            <Navbar />
            <main className="mx-auto sm:w-90 lg:w-2/3 text-gray-700 text-xl items-center my-5">
                <div className="container-fluid my-5 px-0" style={{Width: '100%'}}>
                <div>
                <div
                    style={{
                        backgroundImage: `url(${PORTADA})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        height: '100vh',
                        width: '100vw',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                <div className='box-text'>
                <h1 className="title" >Cursos del ARJÉ</h1>
                <h2 className='subtitle' style={{ fontSize: '2rem', maxWidth: '600px'}}>Cultiva  tu intelecto y despierta tu curiosidad con los cursos del ARJÉ</h2>
                <p style={{ fontSize: '16px', color: 'whitesmoke', maxWidth: '600px' }}>
                Este curso de programación en Python es una excelente opción para aquellos que buscan aprender un lenguaje de programación moderno y versátil. El curso proporciona una introducción sólida a los conceptos fundamentales de la programación en Python, desde la sintaxis básica hasta herramientas más avanzadas como funciones y bibliotecas. El curso también ofrece una variedad de proyectos que permiten a los estudiantes aplicar los principios que aprendieron en un entorno práctico. El curso está dirigido a personas sin experiencia previa en programación, así como a aquellos que buscan profundizar su conocimiento. Con una combinación de contenido teórico y práctico, los estudiantes adquirirán las habilidades necesarias para realizar sus proyectos de programación con confianza
                </p>
                <center>
                <Link className="button-primary" to="/contacto">
                    <span><i className="fa-brands fa-whatsapp"></i>  Contáctanos</span>
                </Link>
                
                </center>
                </div>
      
                </div>
                <div className="container-fluid my-5 py-2 px-0" style={{Width: '100%', backgroundColor: 'var(--black)'}}>
                    <center>
                    <h1 className="my-5" style={{color: 'var(--green500)', textAlign: 'center'}} >Cursos</h1>
                    <p style={{color: 'var(--cyan100)'}}>Revisa nuestra oferta de cursos</p>
                    <div className="row">
                        
                            <>
                            {
                                Object.keys(cursos).map((curso, index) => {
                                    return (
                                        <Curso
                                            key={index}
                                            nombre={cursos[curso].nombre}
                                            descripcion={cursos[curso].descripcion}
                                            img={cursos[curso].img}
                                            path={cursos[curso].path}
                                        />
                                    )
                                })
                            }
                            </>
                        
                    </div>
                    </center>
                </div>
                </div>
                </div>
            </main>
            <Footer />
        </Layout>
    )
}
export default Cursos;