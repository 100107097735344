import Layout from "../../hocs/layouts/Layout";
import Navbar from "../../components/navigation/Navbar";
import React, {useState} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Footer from "../../components/navigation/Footer";
import WidePost from "../../components/boxes/WidePost";
import FormCurso from "../../components/boxes/FormCurso";
import {Link} from 'react-router-dom';
import AnimatedTriangles from "../../components/animation/AnimatedTriangles";
import ImagePopup from "../../components/boxes/ImagePopup";
import Curso from "../../components/boxes/Curso";


//images
import PORTADA from "../../assets/images/CURSO_PYTHON.png";
import img1 from "../../assets/images/CURSO_PYTHON2.png";
import img2 from "../../assets/images/CALL_TO_ACTION.png";
import img3 from "../../assets/images/CURSO_PYTHON3.png";
import CURSO1 from "../../assets/images/1.png";
import CURSO2 from "../../assets/images/2.png";
import CURSO3 from "../../assets/images/3.png";
import CURSO4 from "../../assets/images/4.png";
import CURSO5 from "../../assets/images/5.png";
import CURSO6 from "../../assets/images/6.png";

//video
import VIDEO_PORTADA from "../../assets/videos/PORTADA_CURSOS_GRATUITOS.mp4";

function CursosGratuitos(){
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleTriangleClick = () => {
        document.getElementById('next-section').scrollIntoView({ behavior: 'smooth' });
      };
    const cursos = {
        curso1:{ nombre: "Curso de Geometría", descripcion: "Aprende lo básico de geometría analítica y descriptiva", img: CURSO1, path: "/curso-python"},
        curso2:{ nombre: "Introducción a la Física", descripcion: "Notas de introducción a la Física y al movimiento. Estudio de cinemática", img: CURSO2, path: "#"},
        curso3:{ nombre: "Presentación sobre el Método científico", descripcion: "Breve presentación sobre los pasos fundamentales del método científico", img: CURSO3, path: "/Cursos-de-alto-rendimiento"},
        curso4:{ nombre: "Curso de electrostática", descripcion: "Curso avanzado sobre electrostática. Módulo impartido en 2021 en línea en los cursos de alto rendimiento.", img: CURSO4, path: "/Cursos-de-alto-rendimiento"},
        curso5:{ nombre: "Taller de electrostática", descripcion: "Resolución de problemas sobre electrostática del libro Física del autor Resnik.", img: CURSO5, path: "/Cursos-de-alto-rendimiento"},
        curso6:{ nombre: "Club de lectura", descripcion: "En esta sesión en vivo del 2021, exploramos distintos libros clásicos y sus autores", img: CURSO6, path: "/Cursos-de-alto-rendimiento"}
    };
    
    return (
        <Layout>
            <Navbar />
            <main className="mx-auto sm:w-90 lg:w-2/3 text-gray-700 text-xl items-center my-5">
                <div className="container-fluid my-5 px-0" style={{Width: '100%'}}>
                <div>
                <div className="video-container">
        <video autoPlay muted loop poster={PORTADA}>
          <source src={VIDEO_PORTADA} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay">
          <center>
            <h1 className="text-white">Cursos gratuitos del Arjé</h1>
            <p className="text-white bg-black">Disfruta de nuestros cursos gratuitos. Excelente contenido para ayudarte en el progreso de tu aprendizaje.</p>
            <Link className="button-primary" to="/contacto">
              <span><i className="fa-brands fa-whatsapp"></i> Contáctanos</span>
            </Link>
            
            <AnimatedTriangles onClick={handleTriangleClick} />
          </center>
        </div>
      </div>
                <div  className="container-fluid my-5 py-2 px-0" style={{Width: '100%', backgroundColor: 'var(--black)'}}>
                    <center>
                    <h1 id="next-section" className="my-5" style={{color: 'var(--green500)', textAlign: 'center'}} >Cursos gratuitos</h1>
                    <p style={{color: 'var(--cyan100)'}}>Revisa nuestra oferta de cursos gratuitos</p>
                    <div className="row">
                        
                            <>
                            {
                                Object.keys(cursos).map((curso, index) => {
                                    return (
                                        <Curso
                                            key={index}
                                            nombre={cursos[curso].nombre}
                                            descripcion={cursos[curso].descripcion}
                                            img={cursos[curso].img}
                                            path={cursos[curso].path}
                                        />
                                    )
                                })
                            }
                            </>
                        
                    </div>
                    </center>
                </div>
                </div>
                </div>
            </main>
            <Footer />
        </Layout>
    )
}

export default CursosGratuitos;