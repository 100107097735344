import Layout from "../../hocs/layouts/Layout";
import Navbar from "../../components/navigation/Navbar";
import React from "react";
import Footer from "../../components/navigation/Footer";
import { Container } from "react-bootstrap";
import WidePost from "../../components/boxes/WidePost";
import FormCurso from "../../components/boxes/FormCurso";
import Sway from "../../components/boxes/Sway";
import {Link} from 'react-router-dom';
import AnimatedTriangles from "../../components/animation/AnimatedTriangles";
import PORTADA_video from "../../assets/videos/PORTADA_CURSOS_ALTO_RENDIMIENTO.mp4";

//images
import PORTADA from "../../assets/images/PORTADA_CURSOS_ALTO_RENDIMIENTO.png";
import img1 from "../../assets/images/90a376f6b1ff13535a0a7950ee1b803a_944x532.jpg";
import img2 from "../../assets/images/CALL_TO_ACTION.png";
import img3 from "../../assets/images/FIS_1.jpg";
import img4 from "../../assets/images/FIS_2.jpg";
import img5 from "../../assets/images/FIS_3.jpg";

function Fisica(){
    return(
        <Layout>
            <Navbar />
            <main className="mx-auto sm:w-90 lg:w-2/3 text-gray-700 text-xl items-center my-5">
                <div>
                <div
                    style={{
                        backgroundImage: `url(${PORTADA})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        height: '100vh',
                        width: '100vw',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                <div className='box-text'>
                <h1 className="title" >Curso de Física de Alto Rendimiento</h1>
                <h2 className='subtitle' style={{ fontSize: '2rem'}}>Formación científica y tecnológica de alto nivel</h2>
                <p style={{ fontSize: '16px', color: 'whitesmoke', maxWidth: '600px' }}>
                Nuestro Curso de Física de Alto Rendimiento es una excelente manera de aprender los principios básicos de la física de una manera divertida y eficiente. El curso de física de alto rendimiento también ofrece a los alumnos la oportunidad de trabajar en proyectos de investigación científica. Con una experiencia de aprendizaje altamente interactiva y acceso a nuestros instructores y recursos expertos, los estudiantes pueden estar seguros de que están recibiendo la mejor educación física disponible.
                </p>
                <center>
                <Link className="button-primary" to="/contacto">
                    <span><i className="fa-brands fa-whatsapp"></i>  Contáctanos</span>
                </Link>
                <Link className="button-ghost" to="/cursos">
                    <span> <i class="fa fa-download" aria-hidden="true"></i> Descargar plan de estudios</span>
                </Link>
                <AnimatedTriangles />
                </center>
                </div>
      
                </div>
                <div className="video-container">
                    <video autoPlay muted loop poster={PORTADA} >
                        <source src={PORTADA_video} type="video/mp4"/>Your browser does not support the video tag.
                    </video>
                </div>
                </div>
                <div className="mx-10 leading-8 my-5">
                    <h2 className="title">Nuestro Programa</h2>
                    <section class="horizontal-container">
                    
                    <article class="box">
                        <h3>FÍSICA 1</h3>
                        <p>Curso integrado por 6 módulos, de 2 meses cada uno. El propósito de este curso es una introducción a la física y matemáticas de alto nivel. De esta manera, nos aseguramos que recibas una formación científica sobresaliente, que recibas éxitos en competencias de olimpiadas de física y matemáticas, y que logres tu admisión en tu universidad de preferencia. </p>
                    </article>
                    <article class="box">
                        <h3>FÍSICA 2</h3>
                        <p> 
                        Segundo año, curso integrado por 6 módulos. En este curso recibirás matemáticas de alto nivel como lo es el cálculo multivariable, ecuaciones diferenciales, funciones especiales, ente otras. Así como formación fuerte en programación Python, Javascript y electrónica analógica y digital para elaborar prototipos tecnológicos con placas Arduino y placas Raspberry Pi.
                        </p>
                    </article>
                    <article class="box">
                        <h3>FÍSICA 3</h3>
                        <p>
                        Tercer año, curso integrado por 5 módulos. Aprenderás la física moderna como lo es la mecánica cuántica y relativista, astrofísica y cosmología. Todo ésto con la finalidad de orientar al estudiante en trabajos de investigación original y fomentar así la publicación de conocimiento a temprana edad en temas que estén al alcance del alumno.
                        </p>
                    </article>
                    </section>
                </div>
                <center>
                <article class="box-image-container">
                    <h2 >Descarga el programa</h2>
                    <img src={img1} className="img-fluid my-5" />
                    <Link className="button-primary" to="/cursos">
                    <span> <i class="fa fa-download" aria-hidden="true"></i> Descargar plan de estudios</span>
                    </Link>
                </article>
                <div className="my-5" style={{backgroundColor: 'var(--cyan500)', padding: '50px', maxWidth: '400px', borderRadius: '1rem'}}>
                    <h3 style={{color: 'var(--white)'}}>
                        Te otorgamos <span style={{color: 'var(--yellow500)'}}>constancia</span>  al terminar cada uno de nuestros programas
                    </h3>
                    <p style={{color: 'var(--yellow500)'}}><i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i></p>
                </div>
                <Sway
                title=" "
                description="Cursos avanzados de física de Arjé Club de Ciencias. Inscríbete para llevar cursos los sábados o domingos, prepárate para competencias estatales y nacionales. Participa en proyectos de robótica y programación. Desarrolla y comparte tus ideas sobre la astrofísica, la física cuántica y el conocimiento en general."
                link="https://sway.office.com/s/FpjxeNe05gb9we2T/embed"
                />
                </center>
                <section className="my-5">
                <WidePost
                    title="Únete al mejor club de ciencias"
                    description="¡Nuestro trabajo no lo supera nadie! Ofrecemos cursos de alto nivel para formar futuros científicos o ingenieros a través de una formación científica de calidad. Somos un club de ciencias porque la convivencia sana es parte de nuestra comunidad, fomentamos los buenos valores y principios. Apoyamos a nuestros alumnos en todo lo posible para que logren formar un curriculum sólido de éxitos en resultados de olimpiadas de física y matemáticas, presentación de cartel o ponencia en el Congreso Nacional de Física, presentación de prototipos local, estatal y nacional. "
                    imagePath={img2}
                    />
                </section>
                <section className="container">
                    <div className="horizontal-container">
                        <article className="box-image-container">
                            <img src={img3} className="img-fluid" />
                        </article>
                        <article className="box-image-container">
                            <img src={img4} className="img-fluid" />
                        </article>
                        <article className="box-image-container">
                            <img src={img5} className="img-fluid" />
                        </article>
                    </div>
                    <h2 style={{backgroundColor: 'var(--cyan500)', color: 'var(--yellow400)'}} className="my-5 py-2">Inscripciones abiertas! Empezamos sábado 5 de Octubre 2024</h2>
                    <div className="my-5" style={{backgroundColor: 'var(--cyan500)', padding: '50px', width: '100%' , borderRadius: '1rem'}}>
                    <h3 style={{color: 'var(--white)'}}>
                        Costo de la mensualidad de <span style={{color: 'var(--yellow500)'}}>$1200MXN</span>, 4 horas de clase y 2 horas de taller a la semana 
                    </h3>
                    
                </div>
                    <div>
                    <FormCurso 
                    curso="Física de Alto Rendimiento"
                    formphp="formFisicaAltRen.php"
                    />
                    </div>
                </section>
                
            </main>
            <Footer />
        </Layout>
    )
}

export default Fisica;