import Layout from "../../hocs/layouts/Layout";
import Navbar from "../../components/navigation/Navbar";
import React from "react";
import Principal from "../../components/headers/Principal";
import Footer from "../../components/navigation/Footer";
import WidePost from "../../components/boxes/WidePost";
import Form from "../../components/boxes/Form";
import Image from 'react-bootstrap/Image';
import ImagePopup from "../../components/boxes/ImagePopup";
import Curso from "../../components/boxes/Curso";
import { Link } from "react-router-dom";

//images
import img1 from "../../assets/images/90a376f6b1ff13535a0a7950ee1b803a_944x532.jpg";
import img2 from "../../assets/images/HOME1.png";
import img3 from "../../assets/images/HOME2.png";
import img4 from "../../assets/images/HOME3.png";
import club from "../../assets/images/club-de-ciencias.png";
import CURSO1 from "../../assets/images/CURSO1.png";




function Home(){
    
    return (
        <Layout>
            <Navbar />
            <Principal />
            <main className="mx-auto sm:w-90 lg:w-2/3 text-gray-700 text-xl items-center my-5">
                <div className="mx-10 leading-8">
                <h2 className="title">¿Qué Hacemos?</h2>
                <section id="next-section" class="horizontal-container">
                    
                    <article class="box">
                        <h3><i class="fa-solid fa-square-root-variable"></i>  CURSOS DE FÍSICA DE ALTO RENDIMIENTO</h3>
                        <p>Impartimos cursos de física de alto rendimiento para jóvenes con vocación hacia la ciencia. Estos cursos tienen el propósito de formar futuros científicos con cursos de alto nivel académico</p>
                    </article>
                    <article class="box">
                        <h3><i class="fa-solid fa-flask-vial"></i> PROYECTOS DE INVESTIGACIÓN</h3>
                        <p> 
                            Orientamos a estudiantes para realizar trabajos de investigación científica y prototipos tecnológicos para presentar resultados en el Congreso Nacional de Física y convocatorias locales y nacionales.
                        </p>
                    </article>
                    <article class="box">
                        <h3><i class="fa-solid fa-pencil"></i> CENTRO DE ASESORÍAS</h3>
                        <p>
                            Ofrecemos asesorías de física y matemáticas para todos los niveles. También impartimos cursos de preparación para examen de admisión de UNISON, ITH, UES, UTH y TEC DE MONTERREY
                        </p>
                    </article>
                </section>
                <section class="horizontal-container">
         
                <article class="box-borderless">
                    <h2 className="title">Debates científicos</h2>
                    <p>Participa en nuestros debates científicos. Síguenos en redes sociales para estar informado sobre las fechas y los temas a tratar de nuestros debates. El objetivo es que logremos construir conocimiento y reflexionar sobre todos los aspectos posibles. Todo mundo es bienvenido y cualquier punto de vista o posición es válida y respetada. Inclusive dentro de la comunidad científica existen los dogmas, nosotros exploramos todas las posibilidades de una manera crítica y objetiva, guiados por el respeto y el honor. Trae tu taza, tenemos café recién hecho 🙂☕</p>
                </article>
                <article class="box-image-container">
                    <ImagePopup
                    imagePath={img1}
                    />
                    
                </article>
                
                </section>
                <WidePost 
                    title="Únete al mejor club de ciencias"
                    description="Nuestra meta es formar futuros científicos o ingenieros a través de una formación científica de calidad. Somos un club de ciencias porque la convivencia sana es parte de nuestra comunidad, fomentamos los buenos valores y principios. Apoyamos a nuestros alumnos en todo lo posible para que logren formar un curriculum sólido de éxitos en resultados de olimpiadas de física y matemáticas, presentación de cartel o ponencia en el Congreso Nacional de Física, presentación de prototipos local, estatal y nacional. Preparación gratis para examen de admisión para entrar a cualquier Institución pública o privada de México (sólo para nuestros miembros del club)."
                    imagePath={club}
                    />
                <section>
                    <h2 className="title">Pregunta por nuestros cursos de Física y Programación</h2>
                    <div className="horizontal-container">
                        <article className="box-image-container">
                            <ImagePopup
                            imagePath={img2}
                            />
                        </article>
                        <article className="box-image-container">
                        <ImagePopup
                            imagePath={img3}
                            />
                        </article>
                        <article className="box-image-container">
                        <ImagePopup
                            imagePath={img4}
                            />
                        </article>
                    </div>
                    <center>
                    <Link className="button-primary my-5" to="/contacto">
                        <span><i className="fa-brands fa-whatsapp"></i>  Contáctanos</span>
                    </Link>
                    </center>
                    <div>
                        <h2 className="title">Te recomendamos nuestro Curso de programación en Python</h2>
                        

                        <Curso 
                        title="Curso de programación en Python"
                        descripcion="Aprende a programar en Python"
                        img={CURSO1}
                        path="/curso-python"
                        />
                    </div>
                </section>
                </div>

                

            </main>
            <Footer />
        </Layout>
    )
}
export default Home;